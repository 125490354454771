import {useQuery} from '@tanstack/react-query';
import {fetchUserData, fetchWorkspaces} from "../services/user/context";

export const useUserData = () => {
    return useQuery({
        queryKey: ['user'],
        queryFn: fetchUserData,
        staleTime: 3 * 60 * 1000,
        refetchOnWindowFocus: true,
        enabled: true
    });
};

export const useWorkspaces = () => {
    return useQuery({
        queryKey: ['workspaces'],
        queryFn: fetchWorkspaces,
        staleTime: 5 * 60 * 1000,
        refetchOnMount: false,
        enabled: true
    });
};