import { Box, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

// const CustomChip = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0.5, 1.5),
//   borderRadius: '15px',
//   backgroundColor: 'rgba(240, 239, 245, 1)',
//   color: 'black',
//   cursor: 'pointer',
//   border: '1px solid rgba(183, 160, 231, 1)',
//   maxWidth: '190px',
//   marginTop: '10px',
// }));

// const SquareIcon = styled(Box)(({ theme }) => ({
//   width: 12,
//   height: 12,
//   borderRadius: '4px', 
//   border: '1px solid rgba(183, 160, 231, 1)',
// }));

// const SquareCheckedIcon = styled(SquareIcon)(({ theme }) => ({
//   backgroundColor: 'rgba(183, 160, 231, 1)', 
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: 'white',
// }));

// export const CustomChipComponent = ({ chip, setChips }) => {
//   const handleToggle = () => {
//     setChips((prevChips) =>
//       prevChips.map((c) =>
//         c.name === chip.name ? { ...c, checked: !c.checked } : c
//       )
//     );
//   };

//   return (
//     <CustomChip onClick={handleToggle}>
//       <Checkbox
//         checked={chip.checked}
//         onChange={handleToggle}
//         size="small"
//         icon={<SquareIcon />}
//         checkedIcon={
//           <SquareCheckedIcon>
//             <CheckIcon sx={{ fontSize: 14, color: 'black' }} />
//           </SquareCheckedIcon>
//         }
//         sx={{
//           padding: 0,
//           marginRight: 1,
//         }}
//       />
//       <Typography
//         sx={{
//           fontSize: {
//             small: '9px',
//             mobile: '9px',
//             middle: '11px',
//             tablet: '12px',
//             large: '13px',
//             desktop: '14px',
//           },
//           fontFamily: '"Manrope", sans-serif',
//         }}
//       >
//         {chip.name}
//       </Typography>
//     </CustomChip>
//   );
// };

// const ParentComponent = ({ chips }) => (
//   <Box
//     sx={{
//       display: 'flex',
//       flexDirection: 'row',
//       justifyContent: 'space-around',
//       flexWrap: 'wrap',
//       gap: '10px',
//       columnGap: '15px',
//     }}
//   >
//     {chips.map((chip, index) => (
//       <CustomChipComponent key={chip.name} chip={chip} />
//     ))}
//   </Box>
// );

// export default ParentComponent;
const CustomChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: '15px',
  backgroundColor: 'rgba(240, 239, 245, 1)',
  color: 'black',
  cursor: 'pointer',
  border: '1px solid rgba(183, 160, 231, 1)',
  maxWidth: '190px',
  marginTop: '10px',
}));

const SquareIcon = styled(Box)(({ theme }) => ({
  width: 12,
  height: 12,
  borderRadius: '4px',
  border: '1px solid rgba(183, 160, 231, 1)',
}));

const SquareCheckedIcon = styled(SquareIcon)(({ theme }) => ({
  backgroundColor: 'rgba(183, 160, 231, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
}));

export const CustomChipComponent = ({ chip, setChips }) => {
  const handleToggle = () => {
    setChips((prevChips) =>
      prevChips.map((c) =>
        c.name === chip.name ? { ...c, checked: !c.checked } : c
      )
    );
  };

  return (
    <CustomChip onClick={handleToggle}>
      <Checkbox
        checked={chip.checked}
        size="small"
        icon={<SquareIcon />}
        checkedIcon={
          <SquareCheckedIcon>
            <CheckIcon sx={{ fontSize: 14, color: 'black' }} />
          </SquareCheckedIcon>
        }
        sx={{
          padding: 0,
          marginRight: 1,
          pointerEvents: 'none', // Prevent checkbox from stealing click events
        }}
      />
      <Typography
        sx={{
          fontSize: {
            small: '9px',
            mobile: '9px',
            middle: '11px',
            tablet: '12px',
            large: '13px',
            desktop: '14px',
          },
          fontFamily: '"Manrope", sans-serif',
        }}
      >
        {chip.name}
      </Typography>
    </CustomChip>
  );
};

const ParentComponent = ({ chips, setChips }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      gap: '10px',
      columnGap: '15px',
    }}
  >
    {chips.map((chip) => (
      <CustomChipComponent key={chip.name} chip={chip} setChips={setChips} />
    ))}
  </Box>
);

export default ParentComponent;
