import React from 'react';
import {Form, Formik} from 'formik';
import {Button, Checkbox, Container, FormControlLabel, Link, TextField, Typography,} from '@mui/material';
import {workspaceSchema} from '../../utils/workspaceSchema';
import {useNavigate} from 'react-router-dom';
import apiClient from "../../services/auth/baseApi";

const initialValues = {
    orgName: '',
    orgWebsite: '',
    agreedWithProcessingPersonalData: false,
    agreedWithReceivingAdvertising: false,
};

const containerStyle = {
    width: '50vw',
    maxWidth: '450px',
    padding: '30px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    mt: 10,
};

export const font = {
    desktop: '15px',
    large: '15px',
    tablet: '13px',
    middle: '13px',
    mobile: '11px',
    small: '11px',
};

const linkStyle = {
    textDecorationColor: 'gray',
    color: 'gray',
};

const CreateWorkspace = () => {
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            const response = await apiClient.post(
                '/api/public/core/spaces/create',
                {
                    orgName: values.orgName,
                    orgWebsite: values.orgWebsite,
                    agreedWithProcessingPersonalData: values.agreedWithProcessingPersonalData,
                    agreedWithReceivingAdvertising: values.agreedWithReceivingAdvertising,
                }
            );
            if (response.data) {
                navigate('/dashboard')
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    return (
        <Container sx={containerStyle}>
            <Typography variant="h6" gutterBottom>
                Create Workspace
            </Typography>

            <Typography sx={{color: 'gray', fontSize: font, pt: 1}}>
                By signing up you agree to our Terms of Service and Data Processing Policy.
            </Typography>

            <Formik
                initialValues={initialValues}
                validationSchema={workspaceSchema}
                onSubmit={handleSubmit}>
                {({errors, touched, handleChange, handleBlur, values}) => (
                    <Form>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="orgName"
                            name="orgName"
                            label="Organization name"
                            value={values.orgName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.orgName && Boolean(errors.orgName)}
                            helperText={touched.orgName && errors.orgName}
                            InputLabelProps={{
                                style: {fontSize: '13px'},
                            }}
                        />

                        <TextField
                            fullWidth
                            id="orgWebsite"
                            margin="dense"
                            name="orgWebsite"
                            label="Organization website (optional)"
                            value={values.orgWebsite}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.orgWebsite && Boolean(errors.orgWebsite)}
                            helperText={touched.orgWebsite && errors.orgWebsite}
                            InputLabelProps={{
                                style: {fontSize: '13px'},
                            }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="agreedWithProcessingPersonalData"
                                    name="agreedWithProcessingPersonalData"
                                    color="primary"
                                    checked={values.agreedWithProcessingPersonalData}
                                    onChange={handleChange}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        color: 'gray',
                                        display: 'inline',
                                        fontSize: {
                                            desktop: '15px',
                                            large: '15px',
                                            tablet: '13px',
                                            middle: '13px',
                                            mobile: '11px',
                                            small: '11px',
                                        },
                                    }}
                                >
                                    I agree with the&nbsp;
                                    <Link sx={linkStyle}>offer agreement</Link>
                                    &nbsp;and processing of&nbsp;
                                    <Link sx={linkStyle}>personal data</Link>
                                </Typography>
                            }
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="agreedWithReceivingAdvertising"
                                    name="agreedWithReceivingAdvertising"
                                    color="primary"
                                    checked={values.agreedWithReceivingAdvertising}
                                    onChange={handleChange}
                                />
                            }
                            label={
                                <Typography sx={{color: 'gray', fontSize: font}}>
                                    I want to receive advertising newsletters and news to the specified email
                                </Typography>
                            }
                        />

                        <Typography sx={{color: 'gray', fontSize: font, pt: 2}}>
                            When you create a workspace, you get a free 30-day Enterprise plan. After this time, you can
                            choose the desired plan in your personal account or do it right now.
                            {' '}
                            {
                                <Link
                                    sx={{
                                        textDecoration: 'underline',
                                        color: 'black',
                                        textDecorationColor: 'black',
                                    }}
                                >
                                    Select plan
                                </Link>
                            }
                        </Typography>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3, backgroundColor: 'black', borderRadius: '25px', fontSize: font,
                                '&:hover': {
                                    backgroundColor: 'darkgray'
                                },
                                '&:active': {
                                    backgroundColor: 'lightgray',
                                    color: 'black',
                                },
                            }}
                        >
                            Create Workspace
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default CreateWorkspace;
