import {Box, Typography} from "@mui/material";
import {useState} from "react";
import {SalesBox} from "../../../assets/svg/SalesBox";
// import {Bench} from "./Bench";


export const SalesForm = () => {
    const [chosen, setChosen] = useState('bench');
    const [benchModal, setBenchModal] = useState(false);

    return (
        <Box sx={{padding: '0px 15px'}}>
            <Box sx={{
                marginLeft: '0', marginRight: '0',
                marginBottom: '20px', width: '100%',
                position: 'relative'
            }}>
                <SalesBox/>
                <Typography sx={{
                    position: 'absolute',
                    fontWeight: '500',
                    fontSize: {
                        small: '12px',
                        mobile: '12px',
                        middle: '16px',
                        tablet: '20px',
                        large: '24px',
                        desktop: '28px'
                    },
                    fontFamily: '"Manrope", sans-serif',
                    top: '50%', left: '3%',
                    zIndex: 1000,
                    transform: 'translateY(-50%)'
                }}>Sales board</Typography>
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography
                    sx={{
                        color: chosen === 'bench' ? 'black' : 'gray',
                        textDecoration: chosen === 'bench' ? 'underline' : 'none',
                        textDecorationColor: chosen === 'bench' ? 'rgba(183, 160, 231, 1)' : 'transparent',
                        textUnderlineOffset: '5px',
                        mr: {small: 2, mobile: 2, middle: 3, tablet: 4, large: 4, desktop: 5},
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    variant="h5"
                    onClick={() => setChosen('bench')}
                >
                    Bench
                </Typography>
                <Typography
                    sx={{
                        color: chosen === 'clients' ? 'black' : 'gray',
                        textDecoration: chosen === 'clients' ? 'underline' : 'none',
                        textDecorationColor: chosen === 'clients' ? 'rgba(183, 160, 231, 1)' : 'transparent',
                        textUnderlineOffset: '5px',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    variant="h5"
                    onClick={() => setChosen('clients')}
                >
                    Clients
                </Typography>
            </Box>
            {/*{chosen === 'bench' ? <Bench benchModal={benchModal} setBenchModal={setBenchModal}/> : <Clients/>}*/}
        </Box>
    );
}