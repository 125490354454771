import {useLocation} from "react-router-dom";
import LandingHeader from "./LandingHeader";

const LandingHeaderV1 = () => {
    const path = useLocation().pathname;
    return (
        <>
            {path === '/' && <LandingHeader/>}
        </>
    );
}

export default LandingHeaderV1;
