import apiClient from "../auth/baseApi";

export const fetchUserData = async () => {
    try {
        const response = await apiClient.get('/api/public/core/users/profiles/me');
        return response.data;
    } catch (error) {
        console.error('Error fetching workspaces:', error);
    }
}

export const fetchWorkspaces = async () => {
    try {
        const response = await apiClient.get('/api/public/core/spaces/me');
        return response.data;
    } catch (error) {
        console.error('Error fetching workspaces:', error);
    }
}