import {Box} from "@mui/material";
import {ComingSoon} from "../../../assets/svg/ComingSoon";

export const InterviewsForm = () => {
    return (
        <>
            <Box sx={{
                width: '80%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                overflow: 'hidden',
                margin: '0 auto'
            }}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    maxWidth: '100%',
                    textAlign: 'center'
                }}>
                    <ComingSoon/>
                </Box>
            </Box>

        </>
    )
}