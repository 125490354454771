import LandingHeaderV1 from "./components/Landing/LandingHeaderV1";
import Footer from "./components/Landing/Footer";
import { Outlet } from "react-router-dom";

const LandingLayout = () => {
    return (
        <>
            <LandingHeaderV1></LandingHeaderV1>
            <Outlet></Outlet>
            <Footer></Footer>
        </>
    )
}

export default LandingLayout;