import './App.css';
import AppRoutes from './AppRouter';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AppRoutes/>
        </QueryClientProvider>
    )
}

export default App;
