import MainSidebar from "./MainSidebar";
import {Box, Container} from "@mui/material";
import {useUserData} from "../../hooks/useUserContext";
import React from "react";
import {Outlet} from "react-router-dom";

const Workspace = () => {
    const {isLoading: userLoading} = useUserData();

    if (userLoading) return <div>Loading...</div>;

    return (
        <Container sx={{padding: 0}}>
            <Box display="flex" height="100vh">
                <MainSidebar />
                {/* Main Workspace */}
                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <Outlet />
                </Box>
            </Box>
        </Container>
    );
};

export default Workspace;