import {Alert, Box, Button, Container, FormControl, Link, Snackbar, TextField, Typography} from '@mui/material';

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import CheckboxWithText from './CheckboxWithText.jsx';
import onRegistration from "../../services/auth/registration";
import * as Yup from "yup";

const RegistrationForm = () => {
    const navigate = useNavigate();

    const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'info'});

    // Initial form values
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        checkbox: false
    };

    // Form validation schema using Yup
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .matches(/^[A-Za-zА-Яа-яЁёĄĆĘŁŃÓŚŻŹąćęłńóśżź'-]+$/, 'First Name cannot contain special symbols or numbers')
            .required('First Name is required'),
        lastName: Yup.string()
            .matches(/^[A-Za-zА-Яа-яЁёĄĆĘŁŃÓŚŻŹąćęłńóśżź'-]+$/, 'Last Name cannot contain special symbols or numbers')
            .required('Last Name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Password must contain at least 1 uppercase, 1 lowercase, 1 special character, 1 number, and 8+ characters')
            .required('Password is required'),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Repeat Password is required'),
        checkbox: Yup.boolean()
            .oneOf([true], 'You must agree to the terms'),
    });

    // Submit form handler
    const handleSubmit = async (values, {resetForm}) => {
        try {

            const authToken = await onRegistration(values);

            showSnackbar('Verification email sent! Please check your inbox.', 'success');
            resetForm();
            navigate('/dashboard');
        } catch (error) {
            showSnackbar('Something went wrong. Please try again.', 'error');
            console.error('Error during registration:', error.response ? error.response.data : error.message);
        }
    };

    // Show snackbar notification
    const showSnackbar = (message, severity) => {
        setSnackbar({open: true, message, severity});
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 8,
                width: {xs: 300, sm: 400, md: 500},
                justifyContent: 'space-around',
                alignItems: 'center',
                borderRadius: '20px',
                padding: '20px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
            }}
        >
            <Typography variant="h5" sx={{textAlign: 'center', p: 1}}>Sign Up</Typography>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({errors, touched}) => (
                    <Form style={{width: '100%'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                width: '100%'
                            }}
                        >
                            {["firstName", "lastName", "email", "password", "repeatPassword"].map((field) => (
                                <FormControl key={field}>
                                    <Field
                                        as={TextField}
                                        type={field.includes('password') ? 'password' : 'text'}
                                        name={field}
                                        label={`${field.replace(/([A-Z])/g, ' $1').trim()} *`}
                                        variant="outlined"
                                        fullWidth
                                        error={touched[field] && Boolean(errors[field])}
                                        helperText={touched[field] && errors[field]}
                                    />
                                </FormControl>
                            ))}

                            <FormControl>
                                <Field type="checkbox" name="checkbox" component={CheckboxWithText}/>
                                <ErrorMessage name="checkbox" component="div" style={{color: 'red'}}/>
                            </FormControl>

                            <Button type="submit" variant="contained" sx={{mt: '10px'}}>
                                Sign Up
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>

            <Typography sx={{textAlign: 'center', pt: '10px', color: 'gray'}}>
                Already have an account?{' '}
                <Link
                    onClick={() => navigate('/login')}
                    sx={{cursor: 'pointer', color: 'primary.main'}}
                >
                    Sign In
                </Link>
            </Typography>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RegistrationForm;
