import apiClient from './baseApi';

const onRegistration = async (values) => {
    try {
        const response = await apiClient.post('/api/public/auth/registration', {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName
        });
        return response.data.authSession
    } catch (error) {
        console.error('Registration error:', error.response?.data || error.message);
        throw error;
    }
};

export default onRegistration;
