
const QuibenchLogo = ({ width="180", height="44" }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 180 44" fill="none">
        <path d="M21 4L39 22L21 40" stroke="#B7A0E7" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 10L25 22L13 34" stroke="#B7A0E7" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="-0.0703125" y="22" width="11" height="11" rx="5.5" transform="rotate(-45 -0.0703125 22)" fill="#B7A0E7"/>
        <path d="M163.852 30.6399V13.3599H167.116V20.4639H174.844V13.3599H178.108V30.6399H174.844V23.5119H167.116V30.6399H163.852Z" fill="#B7A0E7"/>
        <path d="M152.882 31C151.154 31 149.662 30.624 148.406 29.872C147.158 29.12 146.194 28.068 145.514 26.716C144.842 25.364 144.506 23.792 144.506 22C144.506 20.208 144.842 18.636 145.514 17.284C146.194 15.932 147.158 14.88 148.406 14.128C149.662 13.376 151.154 13 152.882 13C154.866 13 156.53 13.492 157.874 14.476C159.226 15.46 160.178 16.792 160.73 18.472L157.442 19.384C157.122 18.336 156.582 17.524 155.822 16.948C155.062 16.364 154.082 16.072 152.882 16.072C151.786 16.072 150.87 16.316 150.134 16.804C149.406 17.292 148.858 17.98 148.49 18.868C148.122 19.756 147.938 20.8 147.938 22C147.938 23.2 148.122 24.244 148.49 25.132C148.858 26.02 149.406 26.708 150.134 27.196C150.87 27.684 151.786 27.928 152.882 27.928C154.082 27.928 155.062 27.636 155.822 27.052C156.582 26.468 157.122 25.656 157.442 24.616L160.73 25.528C160.178 27.208 159.226 28.54 157.874 29.524C156.53 30.508 154.866 31 152.882 31Z" fill="#B7A0E7"/>
        <path d="M127.5 30.6399V13.3599H130.812L138.324 24.8799V13.3599H141.636V30.6399H138.324L130.812 19.1199V30.6399H127.5Z" fill="#B7A0E7"/>
        <path d="M112.865 30.6399V13.3599H124.145V16.4079H116.129V20.1039H122.705V23.1519H116.129V27.5919H124.145V30.6399H112.865Z" fill="#B7A0E7"/>
        <path d="M97.1035 30.6399V13.3599H104.016C105.248 13.3599 106.244 13.6079 107.004 14.1039C107.764 14.5919 108.32 15.2079 108.672 15.9519C109.024 16.6959 109.2 17.4479 109.2 18.2079C109.2 19.1759 108.98 19.9799 108.54 20.6199C108.108 21.2599 107.512 21.6879 106.752 21.9039V21.3039C107.832 21.5279 108.64 22.0279 109.176 22.8039C109.72 23.5799 109.992 24.4479 109.992 25.4079C109.992 26.4399 109.8 27.3479 109.416 28.1319C109.04 28.9159 108.464 29.5319 107.688 29.9799C106.912 30.4199 105.936 30.6399 104.76 30.6399H97.1035ZM100.416 27.5679H104.424C104.864 27.5679 105.256 27.4759 105.6 27.2919C105.944 27.0999 106.212 26.8359 106.404 26.4999C106.604 26.1559 106.704 25.7519 106.704 25.2879C106.704 24.8799 106.62 24.5159 106.452 24.1959C106.284 23.8759 106.028 23.6239 105.684 23.4399C105.348 23.2479 104.928 23.1519 104.424 23.1519H100.416V27.5679ZM100.416 20.1039H103.968C104.336 20.1039 104.664 20.0399 104.952 19.9119C105.24 19.7839 105.468 19.5879 105.636 19.3239C105.804 19.0519 105.888 18.7039 105.888 18.2799C105.888 17.7519 105.724 17.3079 105.396 16.9479C105.068 16.5879 104.592 16.4079 103.968 16.4079H100.416V20.1039Z" fill="#B7A0E7"/>
        <path d="M89.7617 30.6399V13.3599H93.0257V30.6399H89.7617Z" fill="white"/>
        <path d="M78.659 30.9999C77.267 30.9999 76.043 30.7199 74.987 30.1599C73.931 29.5919 73.107 28.7959 72.515 27.7719C71.923 26.7479 71.627 25.5439 71.627 24.1599V13.3839L74.939 13.3599V24.1359C74.939 24.7039 75.035 25.2199 75.227 25.6839C75.419 26.1479 75.683 26.5479 76.019 26.8839C76.363 27.2199 76.759 27.4799 77.207 27.6639C77.663 27.8399 78.147 27.9279 78.659 27.9279C79.187 27.9279 79.675 27.8359 80.123 27.6519C80.579 27.4679 80.975 27.2079 81.311 26.8719C81.647 26.5359 81.907 26.1359 82.091 25.6719C82.283 25.2079 82.379 24.6959 82.379 24.1359V13.3599H85.691V24.1599C85.691 25.5439 85.395 26.7479 84.803 27.7719C84.211 28.7959 83.387 29.5919 82.331 30.1599C81.275 30.7199 80.051 30.9999 78.659 30.9999Z" fill="white"/>
        <path d="M66.472 31L61.432 25.984L63.448 23.968L68.464 28.984L66.472 31ZM60.376 31C58.648 31 57.156 30.624 55.9 29.872C54.652 29.12 53.688 28.068 53.008 26.716C52.336 25.364 52 23.792 52 22C52 20.208 52.336 18.636 53.008 17.284C53.688 15.932 54.652 14.88 55.9 14.128C57.156 13.376 58.648 13 60.376 13C62.104 13 63.592 13.376 64.84 14.128C66.096 14.88 67.06 15.932 67.732 17.284C68.412 18.636 68.752 20.208 68.752 22C68.752 23.792 68.412 25.364 67.732 26.716C67.06 28.068 66.096 29.12 64.84 29.872C63.592 30.624 62.104 31 60.376 31ZM60.376 27.928C61.472 27.944 62.384 27.708 63.112 27.22C63.84 26.732 64.384 26.04 64.744 25.144C65.112 24.248 65.296 23.2 65.296 22C65.296 20.8 65.112 19.76 64.744 18.88C64.384 18 63.84 17.316 63.112 16.828C62.384 16.34 61.472 16.088 60.376 16.072C59.28 16.056 58.368 16.292 57.64 16.78C56.912 17.268 56.364 17.96 55.996 18.856C55.636 19.752 55.456 20.8 55.456 22C55.456 23.2 55.636 24.24 55.996 25.12C56.364 26 56.912 26.684 57.64 27.172C58.368 27.66 59.28 27.912 60.376 27.928Z" fill="white"/>
        </svg>
    )
}
export default QuibenchLogo;