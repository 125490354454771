import axios from 'axios';
import {KEYUTIL, KJUR} from 'jsrsasign';

const BASE_URL = 'https://quibench.io';
const JWKS_URL = `${BASE_URL}/.well-known/jwks.json`;
const API_TIMEOUT = 20000;

// Кеш для JWKS ключей
let jwksCache = {
    keys: [],
    expiry: 0
};

async function fetchJWKS() {
    try {
        const {data} = await axios.get(JWKS_URL, {
            timeout: 5000,
            useCredentials: true

        });
        jwksCache = {
            keys: data.keys,
            expiry: Date.now() + 3600000 // Кешируем на 1 час
        };
        return data.keys;
    } catch (error) {
        console.error('JWKS fetch failed:', error);
        throw error;
    }
}

async function getPublicKey(kid) {
    if (Date.now() > jwksCache.expiry || !jwksCache.keys.length) {
        await fetchJWKS();
    }

    const key = jwksCache.keys.find(k => k.kid === kid);
    if (!key) throw new Error('Key not found in JWKS');

    return KEYUTIL.getKey(key);
}

async function verifyJWT(token) {
    try {
        const header = KJUR.jws.JWS.readSafeJSONString(
            decodeURIComponent(escape(atob(token.split('.')[0].replace(/-/g, '+').replace(/_/g, '/'))))
        );

        const publicKey = await getPublicKey(header.kid);
        return KJUR.jws.JWS.verifyJWT(token, publicKey, {
            alg: ['RS256'],
            iss: BASE_URL,
            gracePeriod: 120
        });
    } catch (error) {
        console.error('JWT verification failed:', error);
        return false;
    }
}

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || BASE_URL,
    timeout: API_TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
});

const tokenManager = {
    getAccessToken: () => localStorage.getItem('access_token'),
    getRefreshToken: () => localStorage.getItem('refresh_token'),

    setTokens: ({access_token, refresh_token}) => {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
    },

    clearTokens: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    },

    verifyAccessToken: async () => {
        const token = localStorage.getItem('access_token');
        return token ? await verifyJWT(token) : false;
    }
};


apiClient.interceptors.request.use(async config => {
    if (config.url.includes('/api/public/auth/') || config.url.includes('/.well-known/jwks.json')) return config;

    const isValid = await tokenManager.verifyAccessToken();
    if (!isValid) {
        throw new axios.Cancel('Invalid token');
    }

    config.headers.Authorization = `Bearer ${tokenManager.getAccessToken()}`;
    return config;
});

let isRefreshing = false;
let queue = [];

const processQueue = (error) => {
    queue.forEach(prom => error ? prom.reject(error) : prom.resolve());
    queue = [];
};

apiClient.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response?.status !== 401 || originalRequest._retry) {
            return Promise.reject(error);
        }

        if (!isRefreshing) {
            isRefreshing = true;
            originalRequest._retry = true;

            try {
                const {data} = await axios.post('/api/public/auth/refresh', {
                    refreshToken: tokenManager.getRefreshToken()
                }, {
                    baseURL: BASE_URL,
                    skipAuth: true
                });

                tokenManager.setTokens({
                    access_token: data.access_token,
                    refresh_token_token: data.refresh_token
                });

                processQueue();
                return apiClient(originalRequest);
            } catch (refreshError) {
                tokenManager.clearTokens();
                window.dispatchEvent(new CustomEvent('auth-error', {
                    detail: {error: refreshError}
                }));
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }

        return new Promise((resolve, reject) => {
            queue.push({resolve, reject});
        }).then(() => apiClient(originalRequest))
            .catch(Promise.reject);
    }
);

export const auth = {
    login: async (credentials) => {
        const params = new URLSearchParams()
        params.append('username', credentials.email)
        params.append('password', credentials.password)
        const {data} = await apiClient.post('/api/public/auth/login', params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        tokenManager.setTokens({
            access_token: data.access_token,
            refresh_token: data.refresh_token
        });
    },

    logout: () => {
        tokenManager.clearTokens();
        window.dispatchEvent(new Event('auth-logout'));
    },

    getAccessToken: () => tokenManager.getAccessToken()
};

export default apiClient;