import {Container} from '@mui/material'



const Footer = () => {

    return (
        <Container></Container>
    )
}

export default Footer;