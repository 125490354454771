import { Box } from '@mui/material'

export const SalesBox = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', position: 'relative', borderRadius: '30px' }}>
            <Box
                sx={{
                    position: 'relative',
                    borderTopRightRadius: {
                        desktop: '50px',
                        large: '45px',
                        tablet: '40px',
                        middle: '30px',
                        mobile: '20px',
                        small: '20px'
                    },
                    borderTopLeftRadius: {
                        desktop: '30px',
                        large: '25px',
                        tablet: '25px',
                        middle: '20px',
                        mobile: '15px',
                        small: '15px'
                    },
                    borderBottomLeftRadius: {
                        desktop: '30px',
                        large: '25px',
                        tablet: '25px',
                        middle: '20px',
                        mobile: '15px',
                        small: '15px'
                    },
                    height: {
                        desktop: '60px',
                        large: '55px',
                        tablet: '50px',
                        middle: '30px',
                        mobile: '20px',
                        small: '20px'
                    },
                    width: '18%',
                    background: 'linear-gradient(97.84deg, #E3DBF4 3.02%, #CDC0E9 160.19%)',
                    zIndex: 6,
                }}
            />

            {[5, 4, 3, 2].map((zIndex) => (
                <Box
                    key={zIndex}
                    sx={{
                        position: 'relative',
                        borderTopRightRadius: {
                            desktop: '50px',
                            large: '45px',
                            tablet: '40px',
                            middle: '30px',
                            mobile: '20px',
                            small: '20px'
                        },
                        height: {
                            desktop: '60px',
                            large: '55px',
                            tablet: '50px',
                            middle: '30px',
                            mobile: '20px',
                            small: '20px'
                        },
                        width: '26%',
                        background: 'linear-gradient(97.84deg, #E3DBF4 3.02%, #CDC0E9 140.19%)',
                        marginLeft: {
                            small: '-6vw',
                            mobile: '-6vw',
                            middle: '-5vw',
                            tablet: '-4.5vw',
                            large: '-4vw',
                            desktop: '-4vw'
                        },
                        zIndex: zIndex,
                    }}
                />
            ))}

            <Box
                sx={{
                    position: 'relative',
                    height: {
                        desktop: '60px',
                        large: '55px',
                        tablet: '50px',
                        middle: '30px',
                        mobile: '20px',
                        small: '20px'
                    },
                    borderTopRightRadius: {
                        desktop: '30px',
                        large: '25px',
                        tablet: '25px',
                        middle: '20px',
                        mobile: '15px',
                        small: '15px'
                    },
                    borderBottomRightRadius: {
                        desktop: '30px',
                        large: '25px',
                        tablet: '25px',
                        middle: '20px',
                        mobile: '15px',
                        small: '15px'
                    },
                    width: {
                        small: '18%',
                        mobile: '18%',
                        middle: '16%',
                        tablet: '14%',
                        large: '12%',
                        desktop: '12%'
                    },
                    background: 'linear-gradient(97.84deg,rgb(197, 184, 228) 3.02%, #CDC0E9 140.19%)',
                    marginLeft: {
                        small: '-5vw',
                        mobile: '-5vw',
                        middle: '-5vw',
                        tablet: '-4.5vw',
                        large: '-4vw',
                        desktop: '-4vw'
                    },
                    zIndex: 1,
                }}
            />
        </Box>
    );
}