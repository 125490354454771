import {Box, Button, Container, FormControl, Link, TextField, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {auth} from "../../services/auth/baseApi";

const AuthenticationForm = () => {

    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Incorrect email format')
            .required('Email is required'),
        password: Yup.string()
            .required('Enter password'),
    });
    const handleSubmit = async (values, {resetForm}) => {
        try {
            await auth.login(values);
            resetForm();
            navigate('/dashboard');
        } catch (error) {
            console.error('Something went wrong:', error);
        }
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 8,
                width: {
                    small: 300,
                    mobile: 300,
                    middle: 400,
                    tablet: 450,
                    large: 450,
                    desktop: 500,
                },
                justifyContent: 'space-around',
                alignItems: 'center',
                borderRadius: '20px',
                padding: '15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
            }}
        >
            <Box sx={{textAlign: 'center', p: 1}}>
                <Typography>Sign in</Typography>
            </Box>

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({errors, touched}) => (
                    <Form style={{width: '95%'}}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            gap: '10px',
                            width: '100%',
                        }}>

                            <FormControl>
                                <Field
                                    as={TextField}
                                    name="email"
                                    label="Email *"
                                    variant="outlined"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </FormControl>

                            <FormControl>
                                <Field
                                    as={TextField}
                                    name="password"
                                    type="password"
                                    label="Password *"
                                    variant="outlined"
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                            </FormControl>

                            <Link onClick={() => {
                                console.log('Redirecting to password reset page');
                            }}>
                                Forgot Your Password?
                            </Link>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            gap: '15px',
                        }}>
                            <Button type="submit" variant="authButton" sx={{mt: '5px'}}>Sign in</Button>
                            <Button variant="authButton">Continue with GMAIL</Button>
                        </Box>
                    </Form>
                )}
            </Formik>

            <Box sx={{textAlign: 'center', pt: '10px'}}>
                <Typography sx={{margin: 'auto', mb: 1, color: 'gray'}}>
                    Don't have an account?{' '}
                    <Link
                        onClick={() => {
                            navigate('/registration');
                        }}
                        style={{
                            color: 'black',
                            textDecorationColor: 'black',
                            cursor: 'pointer',
                        }}>
                        Sign up
                    </Link>
                </Typography>

                <Typography sx={{
                    textAlign: 'center', color: 'gray', width: '90%', margin: 'auto', fontSize: {
                        small: '12px',
                        mobile: '12px',
                        middle: '14px',
                    }
                }}>
                    By signing in you agree to our terms of Service and Data Processing Policy
                </Typography>
            </Box>
        </Container>
    )
};

export default AuthenticationForm;