import React, {useEffect} from 'react';
import {Box, Button, styled, useTheme} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {KnowledgeSvg} from '../../assets/svg/KnowledgeSvg';
import {InterviewsSvg} from '../../assets/svg/InterviewsSvg';
import {SalesSvg} from '../../assets/svg/SalesSvg';
import {QuestionsSvg} from '../../assets/svg/QuestionsSvg';
import {DashboardSvg} from '../../assets/svg/DashboardSvg';
import {ExitToApp, Help, Person, Settings} from "@mui/icons-material";
import {QuibenchLogoHeader} from "../../assets/svg/QuibenchLogoHeader";
import {useSelectedSidebarElement} from "../../store/sidebarStore";
import {useWorkspaces} from "../../hooks/useUserContext";
import {ReportsSvg} from "../../assets/svg/ReportsSvg";

const SidebarContainer = styled(Box)(({theme}) => ({
    width: '15%',
    minWidth: 180,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px dotted #000',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    gap: '7px',
    boxSizing: 'border-box',
}));

const MenuButton = styled(Button)(({theme, selected}) => ({
    justifyContent: 'flex-start',
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    width: '100%',
    transition: 'all 0.2s ease',
    fontFamily: '"Manrope", sans-serif',
    ...(selected && {
        backgroundColor: theme.palette.action.selected,
        border: '1px solid rgba(183, 160, 231, 1)',
    }),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const IconWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    '& svg': {
        transition: 'all 0.2s ease',
        width: theme.spacing(2.5), // 20px по умолчанию
        height: theme.spacing(2.5),
        [theme.breakpoints.down('lg')]: {
            width: theme.spacing(2.25), // 18px
            height: theme.spacing(2.25)
        },
        [theme.breakpoints.down('md')]: {
            width: theme.spacing(2), // 16px
            height: theme.spacing(2)
        }
    }
}));

const ResponsiveMenuButton = styled(MenuButton)(({theme}) => ({
    '& .MuiButton-startIcon': {
        marginRight: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1)
        }
    },
    '& span': {
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.pxToRem(13)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(12)
        }
    }
}));

const SidebarSection = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
});

const menuItems = [
    {id: 'dashboard', label: 'Dashboard', icon: <DashboardSvg/>, route: '/workspace/dashboard'},
    {id: 'interviews', label: 'Interviews', icon: <InterviewsSvg/>, route: '/workspace/interviews'},
    {id: 'knowledge', label: 'Knowledge', icon: <KnowledgeSvg/>, route: '/workspace/knowledge'},
    {id: 'sales', label: 'Sales', icon: <SalesSvg/>, route: '/workspace/sales'},
    {id: 'reports', label: 'Reports', icon: <ReportsSvg/>, route: '/workspace/reports'},
    {id: 'questions', label: 'FAQ', icon: <QuestionsSvg/>, route: '/workspace/questions'},
];

const settingsItems = [
    {id: 'profile', label: 'Profile', icon: <Person/>},
    {id: 'settings', label: 'Settings', icon: <Settings/>},
    {id: 'help', label: 'Get Help', icon: <Help/>},
    {id: 'logout', label: 'Logout', icon: <ExitToApp/>}
];

export const MainSidebar = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const {data: workspaces} = useWorkspaces();
    const {selectedSidebarElement, setSelectedSidebarElement} = useSelectedSidebarElement();

    useEffect(() => {
        const currentSection = location.pathname.split('/')[1];
        if (currentSection && menuItems.some(item => item.id === currentSection)) {
            setSelectedSidebarElement(currentSection);
        }
    }, [location, setSelectedSidebarElement]);

    // Обработчик навигации
    const handleNavigation = (route) => {
        navigate(route);
    };


    return (

        <>
            <SidebarContainer component="nav">
                {/* Логотип */}
                <Box sx={{
                    pb: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    mb: 2,
                    '& svg': {
                        width: {xs: '80px', sm: '100px', md: '120px'},
                        height: 'auto',
                        transition: 'width 0.3s ease'
                    }
                }}>
                    <QuibenchLogoHeader/>
                </Box>

                {/* Основное меню */}
                <SidebarSection>
                    {menuItems.map((item) => (
                        <ResponsiveMenuButton
                            key={item.id}
                            selected={selectedSidebarElement === item.id}
                            onClick={() => {
                                handleNavigation(item.route)
                                setSelectedSidebarElement(item.id);
                            }}
                            startIcon={
                                <IconWrapper sx={{
                                    background: selectedSidebarElement === item.id &&
                                        'linear-gradient(97.84deg, #E3DBF4 3.02%, #CDC0E9 140.19%)'
                                }}>
                                    {React.cloneElement(item.icon, {
                                        sx: {
                                            color: selectedSidebarElement === item.id
                                                ? theme.palette.primary.main
                                                : theme.palette.text.secondary
                                        }
                                    })}
                                </IconWrapper>
                            }
                        >
                            <Box
                                component="span"
                                sx={{
                                    fontWeight: 500,
                                    color: selectedSidebarElement === item.id
                                        ? theme.palette.primary.main
                                        : theme.palette.text.secondary,
                                    fontSize: '0.875rem',
                                    transition: 'color 0.2s ease',
                                    '&:hover': {
                                        color: theme.palette.primary.dark
                                    }
                                }}
                            >
                                {item.label}
                            </Box>
                        </ResponsiveMenuButton>
                    ))}
                </SidebarSection>

                {/* Settings */}
                <SidebarSection sx={{mt: 'auto'}}>
                    {settingsItems.map((item) => (
                        <ResponsiveMenuButton
                            key={item.id}
                            startIcon={
                                <IconWrapper>
                                    {React.cloneElement(item.icon, {
                                        sx: {
                                            color: theme.palette.text.secondary,
                                            '&:hover': {
                                                color: theme.palette.text.primary
                                            }
                                        }
                                    })}
                                </IconWrapper>
                            }
                            sx={{
                                '&:hover .MuiSvgIcon-root': {
                                    color: theme.palette.text.primary
                                }
                            }}
                        >
                            {item.label}
                        </ResponsiveMenuButton>
                    ))}
                </SidebarSection>
            </SidebarContainer>
        </>
    );
};

export default MainSidebar;
