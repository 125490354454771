import {Navigate, Route, Routes} from 'react-router-dom';
import Home from './components/Landing/Home';
import LandingLayout from './LandingLayout';
import CreateWorkspace from './components/Workspace/CreateWorkspace';
import Workspace from './components/Workspace/Workspace';
import AuthenticationForm from "./components/Auth/AuthenticationForm";
import RegistrationForm from "./components/Auth/RegistrationForm";
import DashboardForm from "./components/Feature/Dashboard/DashboardForm";
import {InterviewsForm} from "./components/Feature/Interviews/InterviewsForm";
import {KnowledgeForm} from "./components/Feature/Knowledge/KnowledgeForm";
import {ReportsForm} from "./components/Feature/Reports/ReportsForm";
import {FAQForm} from "./components/Feature/FAQ/FAQForm";
import {SalesForm} from "./components/Feature/Sales/SalesForm";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingLayout/>}>
                <Route index element={<Home/>}/>
                <Route path="/registration" element={<RegistrationForm/>}/>
                <Route path="/login" element={<AuthenticationForm/>}/>
                <Route path="/createWorkspace" element={<CreateWorkspace/>}/>
            </Route>
            <Route path="/workspace" element={<Workspace/>}>
                <Route path="dashboard" element={<DashboardForm />} />
                <Route path="interviews" element={<InterviewsForm />} />
                <Route path="knowledge" element={<KnowledgeForm />} />
                <Route path="sales" element={<SalesForm />} />
                <Route path="reports" element={<ReportsForm />} />
                <Route path="questions" element={<FAQForm />} />
                <Route index element={<Navigate to="dashboard" replace />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes;
